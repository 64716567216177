import { createRouter, createWebHistory } from 'vue-router';
import ImageGallery from '../components/ImageGallery.vue';

const routes = [

  {
    path: '/ImageGallery',
    name: 'Gallery',
    component: ImageGallery
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;