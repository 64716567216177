<template>
  <div id="app">

    <header class="app-header">
      <h1><a href="/">Welcome to Cat Paradise</a></h1>
    </header>
    <UploadForm />
    <router-link to="/ImageGallery">Открыть галлерею котиков</router-link>
    <router-view>
    </router-view>

  </div>
</template>

<script>

//import HomePage from "./components/HomePage.vue"
import UploadForm from "./components/UploadForm.vue"
export default {
  components: {
    UploadForm

  },
  methods: {
    onImageUploaded() {
      this.$refs.gallery.loadImages();
    },
  },
};
</script>

<style>
.gallery {
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  margin: 10px;
}

.image-item img {
  max-width: 200px;
  height: auto;
}

.app-header {
  background-color: #ffe4c4;
  padding: 20px;
  text-align: center;
}

.cat-gallery {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.cat-card {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.cat-image {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

h1 {
  font-family: 'Arial', sans-serif;
  color: #ff6347;
}

h2 {
  font-family: 'Arial', sans-serif;
  color: #333;
}

p {
  font-family: 'Verdana', sans-serif;
  color: #666;
}
</style>
