<template>
  
  <div>
    <h2>Загрузить изображение котика</h2>
    <form @submit.prevent="submitForm">
      <input type="file" @change="onFileChange" accept="image/*" required multiple/>
      <button type="submit">Загрузить</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import { uploadImage } from '../api';

export default {
  data() {
    return {
      selectedFile: [],
      message: '',
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files;
    },
    async submitForm() {
      if (!this.selectedFile) {
        this.message = 'Пожалуйста, выберите файл.';
        return;
      }
      const formData = new FormData();
      
      for (let i = 0;i < this.selectedFile.length;i++){
          formData.append('image'+i, this.selectedFile[i]);
      } 
      

      try {
        const response = await uploadImage(formData);
        this.message = response.data.message;
        this.$emit('imageUploaded');
      } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
        this.message = 'Ошибка при загрузке изображения.';
      }
    },
  },
};
</script>

<style scoped>
/* Добавьте стили по необходимости */
</style>
