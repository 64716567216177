import axios from 'axios';

const API_URL = 'http://catgenworld.online:3000'; // Адрес вашего бэкенда

// Функция для загрузки изображения
export const uploadImage = (formData) => {
  return axios.post(`${API_URL}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Функция для получения списка изображений
export const fetchImages = () => {
  return axios.get(`${API_URL}/images`);
};

// Функция для получения URL конкретного изображения
export const getImageUrl = (filename) => {
  return `${API_URL}/images/${filename}`;
};
