<template>
  
  <div>
    <h2>Галерея котиков</h2>
    <div class="cat-gallery">
      <div v-for="image in images" :key="image" class="cat-card">
        <img :src="getImageUrl(image)" alt="Cat Image" class="cat-card"/>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchImages, getImageUrl } from '../api.js';

export default {
  data() {
    return {
      images: [],
    };
  },
  methods: {
    async loadImages() {
      try {
        const response = await fetchImages();
        this.images = response.data;
      } catch (error) {
        console.error('Ошибка при получении изображений:', error);
      }
    },
    getImageUrl(filename) {
      return getImageUrl(filename);
    },
  },
  created() {
    this.loadImages();
  },
};
</script>

<style scoped>
.gallery {
  display: flex;
  flex-wrap: wrap;
}
.image-item {
  margin: 10px;
}
.image-item img {
  max-width: 200px;
  height: auto;
}
.app-header {
  background-color: #ffe4c4;
  padding: 20px;
  text-align: center;
}

.cat-gallery {
  
  flex-wrap: wrap; /* Автоматический перенос элементов на новую строку */
  display: flex;
  padding: 20px;
  justify-content: center;
}

.cat-card {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.cat-image {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

h1 {
  font-family: 'Arial', sans-serif;
  color: #ff6347;
}

h2 {
  font-family: 'Arial', sans-serif;
  color: #333;
}

p {
  font-family: 'Verdana', sans-serif;
  color: #666;
}
</style>
